<template>
  <base-material-card inline class="mb-0" icon="mdi-locker-multiple" :title="$t('Câmpuri duplicate')">
    <v-row dense>
      <v-col cols="12" sm="4" md="3" lg="2">
        <v-navigation-drawer permanent>
          <v-list dense nav class="pl-1">
            <v-list-item-group v-model="prefix" mandatory color="primary">
              <template v-for="pre in prefixes">
                <v-list-item :key="pre.value" dense>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(pre.text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-divider v-if="index < reports.length - 1" :key="index"></v-divider> -->
              </template>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-col>
      <v-col cols="12" sm="8" md="9" lg="10">
        <v-row dense>
          <v-col cols="12">
            <v-autocomplete
              v-model="field"
              :label="$t('Câmp')"
              :items="fields"
              hide-details
              return-object
              clearable
              @change="fieldChanged"
            />
          </v-col>
          <v-col cols="12">
            <table-management
              ref="tableold"
              name="tmcorectorold"
              :table="collection"
              :where="where"
              view-type="marc"
              @fieldsChanged="fieldsChanged"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </base-material-card>
</template>
<script>
import TableManagement from "../../../components/containers/TableManagement"
export default {
  components: {
    TableManagement
  },
  data: () => ({
    field: null,
    collection: "marc_cln",
    fields: [],
    prefix: 0,
    where: "",
    prefixes: [
      { text: "Utilizatori", value: "cln" },
      { text: "Monografii", value: "mon" },
      { text: "Inventare", value: "inv" },
      { text: "Articole", value: "art" },
      { text: "Autorități", value: "ath" },
      { text: "Evenimente", value: "evn" },
      { text: "Furnizori", value: "prv" },
      { text: "RMF", value: "rmf" }
    ]
  }),
  computed: {
    appSchema() {
      return this.$store.getters.appSchema
    }
  },
  watch: {
    prefix() {
      this.$log("prefix ", this.prefixes[this.prefix].value)
      this.field = null
      this.where = ""
      this.collection = "marc_" + this.prefixes[this.prefix].value
      this.fields = []
      const tables = this.appSchema.filter(itm => itm.collection == "marc_" + this.prefixes[this.prefix].value)
      this.fieldSchema = {}
      this.generateListFields(tables[0].content, "")
    }
  },
  mounted() {
    const tables = this.appSchema.filter(itm => itm.collection == "marc_cln")
    this.generateListFields(tables[0].content, "")
  },
  methods: {
    fieldsChanged(e) {
      //this.fields = e
    },
    fieldChanged(e) {
      this.$log("fieldChanged ", e)
      if (e) {
        //fields.200.a.1
        const arr = e.value.split(".")
        if (arr.length == 2) {
          this.where = '"' + arr[0] + "." + arr[1] + ".1" + '":{"$exists":true}'
        } else {
          this.where = '"' + arr[0] + "." + arr[1] + "." + arr[2] + ".1" + '":{"$exists":true}'
        }
      } else {
        this.where = ""
      }
    },
    generateListFields(rec, name) {
      //this.$log("view type ", this.viewType)
      for (const prop in rec) {
        if ((prop === "type" || prop === "schema") && rec.type && rec.schema) {
          if (prop === "type") {
            this.generateListFields(rec.schema, name)
          }
        } else {
          let newname = ""
          if (name) {
            newname = name + "." + prop
          } else {
            newname = prop
          }
          if (rec[prop].schema) {
            if (rec[prop].type === "list") {
              let lbl = newname
              if (rec[prop].meta) {
                lbl = rec[prop].meta.label
              }
              const flds = newname.split(".")
              if (flds[1] && flds[0] == "fields" && !flds[2]) {
                lbl = flds[1] + " - " + lbl
                this.fields.push({ text: lbl, value: newname, type: rec[prop].type })
              }
            }
            this.generateListFields(rec[prop].schema, newname)
          } else {
            if (rec[prop].type) {
              if (
                prop != "ctg" &&
                prop != "id" &&
                prop != "company" &&
                prop != "ord" &&
                prop != "link" &&
                prop != "src_id"
              ) {
                let lbl = newname
                if (rec[prop].meta) {
                  lbl = rec[prop].meta.label
                }
                const flds = newname.split(".")
                if (flds[2] && flds[0] == "fields" && flds[3] == "val") {
                  lbl = flds[1] + flds[2] + " - " + lbl
                } else if (flds[0] == "fields" && (flds[2] == "ind1" || flds[2] == "ind2")) {
                  lbl = flds[1] + " - " + lbl
                }
                this.fields.push({ text: lbl, value: newname, type: rec[prop].type })
              }
            }
          }
        }
      }
    }
  }
}
</script>
